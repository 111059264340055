<template>
  <el-dialog :title="!dataForm.recNo ? '新增现场进度' : '修改现场进度'" :close-on-click-modal="false" :visible.sync="visible"
    width="650px">
    <el-form ref="dataForm" :model="dataForm" :rules="dataRule" inline label-width="140px" label-position="right"
      class="border-form">
      <el-row class="set_border">
        <el-col :span="24" class="col-set_border">
          <el-form-item label="进度分类" prop="sceneTypeId" class="item">
            <el-select class="selInput" v-model="dataForm.sceneTypeId" placeholder="请选择" clearable>
              <el-option v-for="item in subjectList" :key="item.displayValue" :label="item.displayName"
                :value="item.displayValue">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="set_border">
        <el-col :span="24" class="col-set_border">
          <el-form-item label="部件" prop="partsId" class="item">
            <el-select  class="selInput" v-model="dataForm.partsId" placeholder="请选择" clearable>
              <el-option v-for="item in proPartsList" :key="item.displayValue" :label="item.displayName"
                :value="item.displayValue">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="set_border">
        <el-col :span="24" class="col-set_border">
          <el-form-item label="标的物" prop="subjectNo" class="item">
            <el-select class="selInput" v-model="dataForm.subjectNo" placeholder="请选择" clearable>
              <el-option v-for="item in $store.state.common.navBarSubjectList" :key="item.displayValue"
                :label="item.displayName" :value="item.displayValue">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="set_border">
        <el-col :span="24" class="col-set_border">
          <el-form-item label="进度描述" prop="progressDescribe" class="item">
            <el-input class="selInput" type="input" v-model="dataForm.progressDescribe" placeholder="请填写">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="set_border">
        <el-col :span="24" class="col-set_border">
          <el-form-item label="进度百分比" prop="proPerId" class="item">
            <el-select class="selInput" v-model="dataForm.proPerId" placeholder="请选择" clearable>
              <el-option v-for="item in proPerList" :key="item.displayValue" :label="item.displayName"
                :value="item.displayValue">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="set_border">
        <el-col :span="24" class="col-set_border">
          <el-form-item label="备注" class="item">
            <el-input class="selInput" type="input" v-model="dataForm.remark" placeholder="请填写">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="formBtnGroup">
        <el-button v-preventReClick class="btn" type="primary" size="small" @click="dataFormSubmit()">保存</el-button>
        <el-button v-preventReClick class="rebtn" size="small" @click="visible = false">取消</el-button>
      </el-row>
    </el-form>
  </el-dialog>
</template>

<script>
import $common from "@/utils/common.js"
export default {
  name: "purchaseDevice-add-or-update",
  data() {
    return {
      visible: true,
      form: {},
      dataForm: {
        workNo: $common.getItem("workNo"),
        partsId: '',
        partsQuantity: '',
        proPerId: "",
        progressDescribe: '',
        sceneTypeId: '',
        subjectNo: '',
        remark: ''
      },
      dataRule: {
        subjectNo: [
          { required: true, message: "标的物不能为空", trigger: "change" }
        ],
        sceneTypeId: [
          { required: true, message: "进度分类不能为空", trigger: "change" }
        ],
        partsId: [
          { required: true, message: "部件不能为空", trigger: "blur" }
        ],
        progressDescribe: [
          { required: true, message: "进度描述不能为空", trigger: "blur" }
        ],
        proPerId: [
          { required: true, message: "进度百分比不能为空", trigger: "blur" }
        ]
      },
      proPartsList: [],
      proPerList: [],
      subjectList: [],
    };
  },
  components: {

  },
  // mounted() {
  // 	this.getSubjectDropDown();
  // },
  methods: {
    getCurrentTime() {
      //获取当前时间并打印
      var _this = this;
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      let hh = new Date().getHours();
      let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
      let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
      return yy + '-' + mm + '-' + dd;
    },
    init(id) {
      this.dataForm = {
        workNo: $common.getItem("workNo"),
        partsId: '',
        partsQuantity: '',
        proPerId: "",
        progressDescribe: '',
        sceneTypeId: '',
        subjectNo: '',
        remark: '',

      }
      this.getProPartsType();
      this.getProPerType();
      this.getSubjectType();
      this.dataForm.recNo = id ? id : 0;
      if (id) {
        this.getDetail();
      }
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields()
        this.visible = true;
      })
    },
    getDetail() {
      this.$http({
        url: this.$store.state.httpUrl + "/business/progressscenerecord/info/" + this.dataForm.recNo,
        method: "get",
        params: {},
      }).then(({
        data
      }) => {
        if (data && data.resultCode === 200) {
          this.dataForm = data.body;
        }
      });
    },
    getProPartsType() {
      this.$http({
        url: this.$store.state.httpUrl + "/common/ProgressPartsInfo/" + $common.getItem("workNo"),
        method: "get",
        params: {},
      }).then(({ data }) => {
        if (data && data.resultCode === 200) {
          this.proPartsList = data.body;
        }
      });
    },
    getProPerType() {
      this.$http({
        url: this.$store.state.httpUrl + "/common/getProgressPercentageInfo/" + $common.getItem("workNo"),
        method: "get",
        params: {},
      }).then(({ data }) => {
        if (data && data.resultCode === 200) {
          this.proPerList = data.body;
        }
      });
    },
    getSubjectType() {
      this.$http({
        url: this.$store.state.httpUrl + "/common/getSubjectInfo/" + $common.getItem("workNo"),
        method: "get",
        params: {},
      }).then(({ data }) => {
        if (data && data.resultCode === 200) {
          this.subjectList = data.body;
        }
      });
    },
    dataFormSubmit() {
      this.$refs["dataForm"].validate(valid => {
        if (valid) {
          let _url = "/business/progressscenerecord/save";
          if (this.dataForm.recNo && this.dataForm.recNo !== 0) {
            _url = "/business/progressscenerecord/update";
          }
          this.$http({
            url: this.$store.state.httpUrl + _url,
            method: "post",
            data: this.$http.adornData(this.dataForm)
          }).then(({
            data
          }) => {
            if (data && data.resultCode === 200) {
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.visible = false;
                  this.$parent.getDataList();
                }
              });
            } else {
              this.$message.error(data.msg);
            }
          });
        }
      });
    },

  }
};
</script>

<style lang="scss" scoped="scoped">
.selItemInput.descAreaLg {
  width: 718px !important;
}

.addImgBox {
  width: 136px;
  height: 136px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.fondstyle {
  color: #3f649f;
  font-size: 15px;
}

.item::v-deep .el-form-item__label {
  color: #00428E !important;
  margin-right: 10px !important;
  font-weight: bold !important;
  background-color: #E1F1FF;
  white-space: nowrap !important;
  border-right: solid #dadada 1px;
}

.border-form .el-row.set_border {
  border-bottom: solid #dadada 1px;
  border-left: solid #dadada 1px;
  border-right: solid #dadada 1px;
}

.border-form .el-row.set_border:first-child {
  border-top: solid #dadada 1px;
}

::v-deep .el-form-item {
  display: flex !important;
  align-items: stretch !important;
  margin-bottom: 0px !important;
}

.selInput {
  width: 405px !important;
}
</style>
